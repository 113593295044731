import * as React from "react";
import moment from "moment";
import { useChatAvailabilityQuery } from "shared-redux";

moment.locale("en");

const styles = {
  banner: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    padding: "5px 15px",
    paddingBottom: 0,
    fontFamily: "OmnesRegular, sans-serif",
    backgroundColor: "rgba(0, 195, 255, .95)",
    borderRadius: "8px",
    margin: "15px",
    boxShadow: "0px 2px 3.84px rgba(0, 0, 0, 0.25)",
  } as React.CSSProperties,
  bannerText: {
    color: "white",
    lineHeight: "18px",
    fontSize: "14px",
    fontFamily: "OmnesRegular, sans-serif",
  } as React.CSSProperties,
  bannerButton: {
    padding: "5px",
    width: "100%",
    borderTop: "1px solid rgba(255, 255, 255, 0.3)",
  } as React.CSSProperties,
  bannerButtonText: {
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
    padding: "5px",
  } as React.CSSProperties,
  boldType: {
    fontWeight: "bold",
  } as React.CSSProperties,
};

const Banner = () => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [isOutsideHours, setIsOutsideHours] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const { data: availabilityData } = useChatAvailabilityQuery({});

  React.useEffect(() => {
    if (!availabilityData || !availabilityData.chatAvailability) return;

    const currentDay = moment().format("ddd").toLowerCase();
    const availability = availabilityData.chatAvailability[currentDay];

    if (availabilityData.chatAvailabilityMessage) {
      setMessage(availabilityData.chatAvailabilityMessage);
    }

    if (!availability.start || !availability.end) {
      setIsOutsideHours(true);
      return;
    }

    const startTime = moment(availability.start, "HH:mm");
    const endTime = moment(availability.end, "HH:mm");
    const isWithinHours = moment().isBetween(startTime, endTime);

    setIsOutsideHours(!isWithinHours);
  }, [availabilityData]);

  const toggleExpanded = () => setExpanded((prev) => !prev);

  const renderExpandButton = () => (
    <div style={styles.bannerButtonText}>
      {expanded ? "Hide" : "Tap for further information"}
    </div>
  );

  const parseHtmlToText = (html: string) => {
    const parts = html.split(/<br\/?>/g).map((part) => part.trim());
    parts.shift();

    return (
      <p style={styles.bannerText}>
        {parts.map((part, index) => {
          if (part.startsWith("<strong>") && part.endsWith("</strong>")) {
            const text = part.replace(/<\/?strong>/g, "");
            return (
              <p key={index} style={styles.boldType}>
                {text}
              </p>
            );
          }

          return (
            <p key={index}>
              {index > 0 ? "\n" : ""}
              {part}
            </p>
          );
        })}
      </p>
    );
  };

  const parseFirstLine = (html: string) => {
    const parts = html.split(/<br\/?>/g).map((part) => part.trim());
    return <p style={styles.bannerText}>{parts[0]}</p>;
  };

  const renderDetails = () => {
    if (!expanded) return null;

    return parseHtmlToText(message);
  };

  if (!isOutsideHours) return null;

  return (
    <div style={styles.banner}>
      {parseFirstLine(message)}
      {renderDetails()}
      <button
        type="button"
        style={styles.bannerButton}
        onClick={toggleExpanded}
      >
        {renderExpandButton()}
      </button>
    </div>
  );
};

export default Banner;
