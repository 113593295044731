import React from "react";
import {
  getPersonalDetails,
  isOwnMessage,
  MessageGroupType,
  resolveUser,
} from "shared-chat";
import { ChatMessage } from "shared-interfaces";
import { getUser, useAppSelector, useChatGetPhysioQuery } from "shared-redux";
import { theme } from "shared-utilities";
import styled from "styled-components/macro";
import ChatAvatar from "./ChatAvatar";
import Message from "./Message";
import MessageWrapper from "./MessageWrapper";

const PhysioAvatar = styled.div({
  display: "flex",
  flexDirection: "column",
  marginLeft: 8,
  justifyContent: "flex-end",
});
const UserAvatar = styled.div({
  display: "flex",
  flexDirection: "column",
  marginRight: 8,
  justifyContent: "flex-end",
});
const Text = styled.div`
  color: ${theme.palette.DARK_GREY};
`;

interface MessageGroupProps {
  messageGroup: MessageGroupType<ChatMessage>;
  lastMessage?: number;
  style: any;
}

const MessageGroup: React.FC<MessageGroupProps> = ({
  messageGroup,
  lastMessage,
  style,
}) => {
  // Store
  const user = useAppSelector(getUser);

  // Computed Variables
  const { isPhysio, messages } = messageGroup;
  const { data: physios } = useChatGetPhysioQuery();
  const combinedUsers = [user, ...(physios ?? [])];
  const correctUser = resolveUser(messageGroup, combinedUsers);
  const { firstName, lastName } = getPersonalDetails(correctUser) ?? {};

  const name = isPhysio ? firstName : `${firstName} ${lastName}`;
  // const sortedMessages = React.useMemo(() => messages.reverse(), [messages]);

  // Renderer
  const renderPhysioAvatar = () => (
    <PhysioAvatar>
      <ChatAvatar user={correctUser!} isPhysio />
    </PhysioAvatar>
  );

  const renderUserAvatar = () => (
    <UserAvatar>
      <ChatAvatar user={user!} />
    </UserAvatar>
  );

  const renderMessage = (
    message: ChatMessage,
    i: number,
    array: ChatMessage[]
  ) => {
    return (
      <MessageWrapper key={message?.id ?? message.tempId}>
        <Message
          key={message.id}
          message={message}
          showIndicator={message?.id === lastMessage}
          isLastMessage={!i}
          isFirstMessage={i === array.length - 1}
          isOwnMessage={isOwnMessage(message)}
        />
      </MessageWrapper>
    );
  };

  return (
    <div
      style={{
        ...style,
        display: "flex",
        flexDirection: "row",
        marginTop: 32,
        paddingRight: 20,
        paddingBottom: 30,
      }}
    >
      {!isPhysio && renderUserAvatar()}
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: !isPhysio ? "flex-start" : "flex-end",
        }}
      >
        <Text css={theme.typography[theme.typography.FONT_NAME].TINY_TEXT}>
          {name}
        </Text>
        {messages.map(renderMessage).reverse()}
      </div>
      {isPhysio && renderPhysioAvatar()}
    </div>
  );
};

export default MessageGroup;
